@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.content-container::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

.content-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust the color as needed */
}

.content-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust the color as needed */
}


.App-link {
  color: #61dafb;
}

/* MainPage.css */

.fade-in-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
}

.fade-in-out.show {
  opacity: 1;
}

.hero-slider-overlay {
  color: white;
  background-color: rgba(0, 0, 0, 0.15);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}